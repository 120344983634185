import { createTheme } from '@mui/material';

export const renosplash_theme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#ff5100',
        },
        secondary: {
            main: '#57a44f',
        },
        error: {
            main: '#f44836',
        },
    },
    typography: {
        fontFamily: 'Manrope, Arial, Helvetica, "sans serif"',
    },
});