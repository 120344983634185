import {
    CssBaseline,
    Box,
    Stack,
    ThemeProvider,
    Link,
    useTheme,
    Button,
    ButtonGroup,
} from '@mui/material';
import { renosplash_theme } from '../../Theme/theme';
import {
    Facebook,
    Instagram,
    YouTube,
    Place,
    Email,
} from '@mui/icons-material';
import Image from 'mui-image';
import logo from '../../Images/logo reno splash.png';
import { FacebookEmbed, InstagramEmbed } from 'react-social-media-embed';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect, useState } from 'react';
import React from 'react';
import styles from './App.module.css';

type RenoSplashData = {
    tickets: {
        visible: boolean;
        link: string;
    };
    instagram: string[];
    facebook: string[];
};

function App() {
    const theme = useTheme();
    /* const facebokEmbedWidth = useMediaQuery(theme.breakpoints.down('sm'))
        ? '350'
        : '750'; */

    const [renoSplashData, setRenoSplashData] = useState({} as RenoSplashData);

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const isMid = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isBig = useMediaQuery(theme.breakpoints.up('md'));

    let igPostWidth: string | number = 326;
    if (isMid) {
        igPostWidth = 500;
    }
    if (isBig) {
        igPostWidth = 700;
    }

    let facebokEmbedWidth: string | number = 326;
    if (isMid) {
        facebokEmbedWidth = 500;
    }
    if (isBig) {
        facebokEmbedWidth = 700;
    }

    useEffect(() => {
        const getData = () => {
            fetch('data/data.json', {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((JsonData) => {
                    setRenoSplashData(JsonData);
                });
        };

        getData();
    }, []);

    return (
        <ThemeProvider theme={renosplash_theme}>
            <CssBaseline />
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{
                    minHeight: '100vh',
                    background:
                        'radial-gradient(circle, hsl(99.13deg 45.82% 49.22%) 0%, hsl(111.43deg 42.86% 19.22%) 100%)',
                }}
            >
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    spacing={6}
                    width="100%"
                >
                    <span
                        style={{
                            display: isSmall ? 'block' : 'none',
                            padding: '1rem',
                        }}
                    >
                        <Image src={logo} width="95vw" />
                    </span>
                    <span
                        style={{
                            display: isMid ? 'block' : 'none',
                            padding: '1rem',
                        }}
                    >
                        <Image src={logo} width="60vw" />
                    </span>
                    <span
                        style={{
                            display: isBig ? 'block' : 'none',
                            padding: '1rem',
                        }}
                    >
                        <Image src={logo} width="40vw" />
                    </span>
                    {renoSplashData.tickets &&
                        renoSplashData.tickets.visible && (
                            <Button
                                variant="contained"
                                size="large"
                                href="https://dice.fm/event/dkgb2v-reno-splash-festival-11th-jul-il-poggiolo-rifugio-reesistente-marzabotto-tickets"
                                style={{ textAlign: 'center' }}
                            >
                                TICKETS
                            </Button>
                        )}

                    <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                    >
                        <Link href="https://www.instagram.com/renosplashfestival">
                            <Instagram
                                fontSize="large"
                                sx={{ color: 'rgba(0,0,0,0.5)' }}
                            />
                        </Link>
                        <Link href="https://www.facebook.com/renosplashfestival/">
                            <Facebook
                                fontSize="large"
                                sx={{ color: 'rgba(0,0,0,0.5)' }}
                            />
                        </Link>
                        <Link href="https://www.youtube.com/@renosplash7598">
                            <YouTube
                                fontSize="large"
                                sx={{ color: 'rgba(0,0,0,0.5)' }}
                            />
                        </Link>
                        <Link href="https://goo.gl/maps/kSZ12mUcunQ64BFHA">
                            <Place sx={{ color: 'rgba(0,0,0,0.5)' }} />
                        </Link>
                    </Stack>

                    {renoSplashData.instagram &&
                        renoSplashData.instagram.length > 0 &&
                        renoSplashData.instagram.map((igPost) => (
                            <div key={igPost}>
                                <InstagramEmbed
                                    url={`https://www.instagram.com/p/${igPost}/`}
                                    width={igPostWidth}
                                    captioned
                                />
                            </div>
                        ))}

                    {renoSplashData.facebook &&
                        renoSplashData.facebook.length > 0 &&
                        renoSplashData.facebook.map((fbPost) => (
                            <div key={fbPost}>
                                <FacebookEmbed
                                    url={`https://www.facebook.com/renosplashfestival/posts/${fbPost}`}
                                    width={facebokEmbedWidth}
                                />
                            </div>
                        ))}
                    <div
                        style={{
                            marginBottom: '20px',
                        }}
                    >
                        <ButtonGroup variant="contained">
                            <Button
                                //variant="outlined"
                                //color="secondary"
                                href="mailto:info@renosplash.com"
                                startIcon={<Email />}
                            >
                                info
                            </Button>
                            <Button
                                //variant="outlined"
                                //color="secondary"
                                href="mailto:mercatino@renosplash.com"
                                startIcon={<Email />}
                            >
                                mercatino
                            </Button>
                            <Button
                                //color="secondary"
                                //variant="outlined"
                                href="mailto:tornei@renosplash.com"
                                startIcon={<Email />}
                            >
                                tornei
                            </Button>
                        </ButtonGroup>
                    </div>
                </Stack>
            </Box>
        </ThemeProvider>
    );
}

export default App;
